import { template as template_09f5cbcb079946a48509eaf9af0a931f } from "@ember/template-compiler";
const FKControlMenuContainer = template_09f5cbcb079946a48509eaf9af0a931f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
