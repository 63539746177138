import { template as template_ff279ab4b76c448294fbebf47498a14a } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_ff279ab4b76c448294fbebf47498a14a(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_ff279ab4b76c448294fbebf47498a14a(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_ff279ab4b76c448294fbebf47498a14a(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
