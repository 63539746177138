import { template as template_7d846d3693714c0d96bff3602e131142 } from "@ember/template-compiler";
const FKText = template_7d846d3693714c0d96bff3602e131142(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
